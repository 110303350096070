import type {
  OrganizationPermissions
} from '~/src/enums/ProjectPermissions'
import {
  ADMIN_PERMISSIONS,
  MANAGER_PERMISSIONS,
  VIEWER_PERMISSIONS
} from '~/src/enums/ProjectPermissions'
import { ProjectRole } from '~/src/enums/ProjectRole'
import { useCurrentProject } from '~/composables/data/useCurrentProject'
import { useUser } from '~/composables/data/useUser'

export function usePermissions() {
  const { user, getCurrentRole } = useUser()
  const { currentProject } = useCurrentProject(user.value?.currentCampaign.defaultProject.id)

  const currentRole = computed(() => {
    return getCurrentRole(user.value, currentProject.value)
  })

  /**
   * Checks if the current user has a specific permission
   */
  const hasPermission = (permission: OrganizationPermissions): boolean => {
    const role = currentRole.value

    if (role === undefined) {
      return false
    }

    switch (role) {
      case ProjectRole.ADMIN:
        return ADMIN_PERMISSIONS.has(permission) ||
          MANAGER_PERMISSIONS.has(permission) ||
          VIEWER_PERMISSIONS.has(permission)
      case ProjectRole.MANAGER:
        return MANAGER_PERMISSIONS.has(permission) ||
          VIEWER_PERMISSIONS.has(permission)
      case ProjectRole.VIEWER:
        return VIEWER_PERMISSIONS.has(permission)
      default:
        return false
    }
  }

  /**
   * Checks if the current user can perform a specific action and is in a valid state
   */
  const canPerform = (permission: OrganizationPermissions): boolean => {
    return !!user.value &&
      !!currentProject.value &&
      hasPermission(permission)
  }

  return {
    currentRole,
    hasPermission,
    canPerform
  }
}
